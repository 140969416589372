var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"class_add"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Class Name ")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"class name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Class name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select Versions ")])]),_c('validation-provider',{attrs:{"name":"version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","state":errors.length > 0 ? false:null,"label":"name","options":_vm.versions},model:{value:(_vm.version_ids),callback:function ($$v) {_vm.version_ids=$$v},expression:"version_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select Shifts ")])]),_c('validation-provider',{attrs:{"name":"shift","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","options":_vm.shifts},model:{value:(_vm.shift_ids),callback:function ($$v) {_vm.shift_ids=$$v},expression:"shift_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select Student Groups ")])]),_c('validation-provider',{attrs:{"name":"student group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","options":_vm.student_groups},model:{value:(_vm.student_group_ids),callback:function ($$v) {_vm.student_group_ids=$$v},expression:"student_group_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-card-text',[_c('span',[_vm._v("Select wings ")])]),_c('validation-provider',{attrs:{"name":"wing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","options":_vm.wings},model:{value:(_vm.wing_ids),callback:function ($$v) {_vm.wing_ids=$$v},expression:"wing_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }